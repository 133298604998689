import * as linkify from 'linkifyjs'
import { store } from '../../src/app/store'

export const sources = [
  { name: 'Booking', val: 'appointment' },
  { name: 'Engagement', val: 'campaign' },
  { name: 'Messenger', val: 'chat' },
  { name: 'Website Contact Form', val: 'contact-us' },
  { name: 'Direct', val: 'direct' },
  { name: 'Google', val: 'google' },
  { name: 'Outlook', val: 'outlook' },
  { name: 'QuickBooks', val: 'quickbooks' },
  { name: 'Reviews', val: 'review' },
  { name: 'Subscriber', val: 'subscriber' },
  { name: 'Thumbtack', val: 'thumbtack' },
  { name: 'Payments', val: 'transaction' }
]

export const reviewSources = [
  { key: 'All Reviews', site_id: '' },
  { key: 'Customer Reviews', site_id: 'DIRECT' },
  { key: 'Acompio', site_id: 'ACOMPIO' },
  { key: 'Brownbook', site_id: 'BROWNBOOK' },
  { key: 'City Squares', site_id: 'CITY_SQUARES' },
  { key: 'Cylex', site_id: 'CYLEX' },
  { key: 'EZ Local', site_id: 'EZLOCAL' },
  { key: 'Facebook', site_id: 'FACEBOOK' },
  { key: 'Find Open', site_id: 'FIND_OPEN' },
  { key: 'Google', site_id: 'GOOGLEMYBUSINESS' },
  { key: 'Holiday Check', site_id: 'HOLIDAY_CHECK' },
  { key: 'iGlobal', site_id: 'I_GLOBAL' },
  { key: 'Judys Book', site_id: 'JUDYS_BOOK' },
  { key: 'My Local Services', site_id: 'MY_LOCAL_SERVICES' },
  { key: 'N49', site_id: 'N49' },
  { key: 'Show Me Local', site_id: 'SHOWMELOCAL' },
  { key: 'Open DI', site_id: 'STADTBRANCHENBUCH' },
  { key: 'TripAdvisor', site_id: 'TRIP_ADVISOR' },
  { key: 'Waze', site_id: 'WAZE' },
  { key: 'Where To', site_id: 'WHERE_TO' },
  { key: 'Yalwa', site_id: 'YALWA' },
  { key: 'Yasabe', site_id: 'YA_SABE' },
  { key: 'Yelp', site_id: 'YELP_API' }
]

export const reviewDateRanges = [
  { label: 'All', value: null },
  { label: 'Last 7 Days', value: '7' },
  { label: 'Last 14 Days', value: '14' },
  { label: 'Last 30 Days', value: '30' },
  { label: 'Last 90 Days', value: '90' },
  { label: 'Last 4 Months', value: '120' },
  { label: 'Last 12 Months', value: '365' },
  { label: 'Custom Date Range', value: 'custom' }
]

export const serviceSources = [
  { name: 'Direct', val: 'direct' },
  { name: 'Thumbtack', val: 'thumbtack' }
]

export const businessDays = {
  monday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 1,
    open_allday: false
  },
  tuesday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 2,
    open_allday: false
  },
  wednesday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 3,
    open_allday: false
  },
  thursday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 4,
    open_allday: false
  },
  friday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 5,
    open_allday: false
  },
  saturday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 6,
    open_allday: false
  },
  sunday: {
    slots: [],
    open: false,
    split: false,
    sortOrder: 7,
    open_allday: false
  }
}

export const days = [
  { label: 'MONDAY', value: 2 },
  { label: 'TUESDAY', value: 3 },
  { label: 'WEDNESDAY', value: 4 },
  { label: 'THURSDAY', value: 5 },
  { label: 'FRIDAY', value: 6 },
  { label: 'SATURDAY', value: 7 },
  { label: 'SUNDAY', value: 1 }
]

export const DAY_WITH_VALUE = {
  MONDAY: {
    dbValue: 2
  },
  TUESDAY: {
    dbValue: 3
  },
  WEDNESDAY: {
    dbValue: 4
  },
  THURSDAY: {
    dbValue: 5
  },
  FRIDAY: {
    dbValue: 6
  },
  SATURDAY: {
    dbValue: 7
  },
  SUNDAY: {
    dbValue: 1
  }
}

export const GOOGLE_STATUS = {
  UNVERIFIED: 'UNVERIFIED',
  COMPLETED: 'COMPLETED',
  PENDING: 'PENDING'
}

export const paymentMethods = [
  { label: 'AMERICAN EXPRESS', value: 'AMERICANEXPRESS' },
  { label: 'CASH', value: 'CASH' },
  { label: 'CHECK', value: 'CHECK' },
  { label: 'DINERS CLUB', value: 'DINERSCLUB' },
  { label: 'DISCOVER', value: 'DISCOVER' },
  { label: 'FINANCING', value: 'FINANCING' },
  { label: 'INVOICE', value: 'INVOICE' },
  { label: 'MASTERCARD', value: 'MASTERCARD' },
  { label: 'TRAVELERS CHECK', value: 'TRAVELERSCHECK' },
  { label: 'VISA', value: 'VISA' },
  { label: 'ANDROID PAY', value: 'ANDROIDPAY' },
  { label: 'APPLE PAY', value: 'APPLEPAY' },
  { label: 'SAMSUNG PAY', value: 'SAMSUNGPAY' },
  { label: 'PAYPAL', value: 'PAYPAL' },
  { label: 'BITCOIN', value: 'BITCOIN' }
]

export const S3 = 'https://dufzo4epsnvlh.cloudfront.net'
export const invoicePath = 'https://gosite-media.s3.us-west-1.amazonaws.com'
export const primaryColor = '#0258ff'
export const secondaryColor = '#082344'
export const bookingProdID = '5ad0ae4594c8bc1cf671e713'
export const MAX_CUSTOM_FIELDS = 5

export const staticCreateReviewWidgetList = [
  {
    user: 'Robert M. Grell',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'Henry L. White',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'Elsie S. Blom',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'James L. Hill',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'Derek R. Conner',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'John',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'Sheri B',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'B. French',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'Sandra J. Rodriguez',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  },
  {
    user: 'Blanche R. Bass',
    review: 'Santiago and SOS are awesome!!!',
    time: new Date(),
    rating: 4
  }
]
export const states = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Alaska', abbreviation: 'AK' },
  { name: 'American Samoa', abbreviation: 'AS' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Federated States Of Micronesia', abbreviation: 'FM' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Guam', abbreviation: 'GU' },
  { name: 'Hawaii', abbreviation: 'HI' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Marshall Islands', abbreviation: 'MH' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Northern Mariana Islands', abbreviation: 'MP' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Palau', abbreviation: 'PW' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Puerto Rico', abbreviation: 'PR' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virgin Islands', abbreviation: 'VI' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' }
]

export const classMapping = {
  '411 ': '411',
  '2FINDLOCAL': '2FindLocal',
  '8COUPONS': '8coupons',
  ABLOCAL: 'ABLocal',
  AIRYELL: 'AirYell',
  ALEXA: 'Alexa',
  ALLONESEARCH: 'AllOneSearch',
  AMERICANTOWNSCOM: 'AmericanTowns',
  APPLE: 'Apple',
  AROUNDME: 'AroundMe',
  AVANTAR: 'Avantar',
  GOOGLE: 'Google',
  YELP: 'Yelp',
  FACEBOOK: 'Facebook',
  GETFAVE: 'GetFave',
  WHITEPAGES: 'WhitePages',
  SWITCHBOARD: 'Switchboard',
  LOCALCOM: 'Local.com',
  MERCHANTCIRCLE: 'MerchantCircle',
  CITYSEARCH: 'CitySearch',
  MAPQUEST: 'MapQuest',
  TELENAV: 'Telenav',
  SUPERPAGES: 'Superpages',
  DEXKNOWS: 'DexKnows',
  TOPIX: 'Topix',
  FOURSQUARE: 'Foursquare',
  YAHOO: 'Yahoo!',
  SHOWMELOCAL: 'ShowMeLocal',
  BING: 'Bing',
  YELLOWISE: 'Yellowise',
  LOCALDATABASE: 'LocalDataBase',
  TUPALO: 'Tupalo',
  YELLOWMOXIE: 'YellowMoxie',
  WHITEYELLOWPAGES: 'White & Yellow Pages',
  LOCALPAGES: 'LocalPages',
  EZLOCAL: 'EZlocal',
  COPILOT: 'CoPilot',
  WHERETO: 'Where To',
  CITYSQUARES: 'CitySquares',
  NAVMII: 'Navmii',
  USCITYNET: 'USCity.net',
  YELLOWPAGECITYCOM: 'YellowPageCity',
  CHAMBEROFCOMMERCECOM: 'Chamber Of Commerce',
  FACTUAL: 'Factual',
  // "AMERICANTOWNSCOM" : "",
  ELOCAL: 'eLocal',
  CITYMAPS: 'CityMaps',
  YASABE: 'YaSabe',
  GOLOCAL247: 'GoLocal247',
  HERE: 'Here',
  YELLOWPAGESGOESGREEN: 'YellowPagesGoesGreen',
  POINTCOM: 'Pointcom',
  OPENDI: 'Opendi',
  VOTEFORTHEBEST: 'VotefortheBest',
  TOMTOM: 'TomTom',
  MYLOCALSERVICES: 'My Local Services',
  N49: 'n49',
  CYLEX: 'Cylex',
  IBEGIN: 'iBegin',
  IGLOBAL: 'iGlobal',
  CREDIBILITYCOM: 'Credibility.com',
  CREDIBILITYREVIEW: 'Credibility Review',
  INSIDERPAGES: 'InsiderPages',
  LOCALSTACK: 'LocalStack',
  YALWA: 'Yalwa',
  BROWNBOOKNET: 'BrownBook',
  BIZWIKI: 'Bizwiki',
  THEINTERNETCHAMBEROFCOMMERCE: 'The Internet Chamber of Commerce',
  SOLEO: 'Soleo',
  GOOGLEMYBUSINESS: 'Google',
  WAZE: 'Waze',
  HOTFROG: 'HotFrog',
  KUDZU: 'Kudzu',
  GOOGLEPLACES: 'Googleplaces',
  PUBLICREPUTATION: 'Publicreputation',
  FINDOPEN: 'Findopen',
  PROPERTYCAPSULE: 'Propertycapsule',
  YPCOM: 'Ypcom',
  BIZWIKICOM: 'Bizwikicom'
}

export const activityList = [
  'website_contact_us',
  'new_message',
  'website_appointment',
  'admin_appointment',
  'website_subscription',
  'review_send',
  'new_review',
  'review_sms',
  'review_email_clicked',
  'contact_create',
  'new_contact_success',
  'website_published',
  'website_delete',
  'website_trash',
  'website_create',
  'website_restore',
  'scheduled_review_request',
  'unscheduled_review_request',
  'user_appointment',
  'user_incomplete_appointment',
  'booking_rejected',
  'booking_rescheduled',
  'invoice_create',
  'invoice_edit',
  'email_opened',
  'invoice_sent',
  'invoice_delete',
  'invoice_cancel',
  'sms_sent',
  'invoice_paid',
  'sms_opened',
  'payment_failed',
  'payment_success',
  'reminder_sent_email',
  'reminder_sent_sms',
  'invoice_open',
  'invoice_scheduled',
  'getpaid_sent',
  'getpaid_sms_sent',
  'getpaid_delete',
  'getpaid_cancel',
  'getpaid_resend',
  'getpaid_sms_resend',
  'getpaid_resend_email_opened',
  'getpaid_paid',
  'getpaid_create',
  'new_payment',
  'user_appointment',
  'email_delivery_error'
]
export const dashboardActivityList = [
  'website_contact_us',
  'new_message',
  'website_appointment',
  'admin_appointment',
  'website_subscription',
  'review_send',
  'new_review',
  'review_sms',
  'review_email_clicked',
  'contact_create',
  'new_contact_success',
  'website_published',
  'website_delete',
  'website_trash',
  'website_create',
  'website_restore',
  'scheduled_review_request',
  'unscheduled_review_request',
  'staff_removed',
  'staff_created',
  'business_edit',
  'business_create',
  'account_fb_connect',
  'account_fb_disconnect',
  'account_gmb_connect',
  'account_gmb_disconnect',
  'assign_message',
  'invoice_create',
  'invoice_edit',
  'email_opened',
  'invoice_sent',
  'invoice_delete',
  'invoice_cancel',
  'sms_sent',
  'invoice_paid',
  'sms_opened',
  'payment_failed',
  'payment_success',
  'reminder_sent_email',
  'reminder_sent_sms',
  'invoice_open',
  'invoice_scheduled',
  'getpaid_sent',
  'getpaid_sms_sent',
  'getpaid_delete',
  'getpaid_cancel',
  'getpaid_resend',
  'getpaid_sms_resend',
  'getpaid_resend_email_opened',
  'getpaid_paid',
  'getpaid_create',
  'my_account',
  'profile_photo',
  'change_password',
  'account_settings',
  'facebook_login_linked',
  'google_login_linked',
  'facebook_login_unlinked',
  'google_login_unlinked',
  'calendar_sync',
  'calendar_unsync',
  'booking_rejected',
  'booking_rescheduled',
  'new_email_campaign_request',
  'private_review',
  'new_payment',
  'service_created',
  'service_updated',
  'service_deleted',
  'tfa_enabled',
  'tfa_disabled',
  'user_appointment'
]
export const teamActivityList = [
  'website_contact_us',
  // "new_message",
  'website_appointment',
  'admin_appointment',
  'website_subscription',
  'review_send',
  'new_review',
  'review_sms',
  'review_email_clicked',
  'contact_create',
  'new_contact_success',
  'website_published',
  'website_delete',
  'website_trash',
  'website_create',
  'website_restore',
  'scheduled_review_request',
  'unscheduled_review_request',
  'business_edit',
  'business_create',
  'account_fb_connect',
  'account_fb_disconnect',
  'account_gmb_connect',
  'account_gmb_disconnect',
  'assign_message'
]
export const bookingActivity = [
  'booking_website_create',
  'booking_accepted',
  'booking_rejected',
  'admin_appointment',
  'booking_rescheduled',
  'appointment_updated'
]

export const prospectToCustomerMessageTimeout = 96 // hours

export const paymentActivities = [
  'invoice_create',
  'invoice_edit',
  'invoice_sent',
  'invoice_delete',
  'invoice_cancel',
  'invoice_paid',
  'payment_failed',
  'payment_success',
  'invoice_open',
  'invoice_scheduled',
  'getpaid_sent',
  'getpaid_sms_sent',
  'getpaid_delete',
  'getpaid_cancel',
  'getpaid_resend',
  'getpaid_sms_resend',
  'getpaid_resend_email_opened',
  'getpaid_paid',
  'getpaid_create',
  'new_payment'
]

export const bookingActivities = [
  'website_appointment',
  'admin_appointment',
  'booking_accepted',
  'booking_rejected',
  'booking_rescheduled',
  'user_appointment',
  'user_incomplete_appointment'
]

export const messengerActivities = ['new_message']

export const reviewsActivities = [
  'review_send',
  'new_review',
  'review_sms',
  'scheduled_review_request',
  'unscheduled_review_request'
]

export const countActivites = ['review_email_clicked']

function containsAny(str = '', substrings = []): string | null {
  for (let i = 0; i !== substrings.length; i++) {
    const substring = substrings[i]
    if (str.includes(substring)) {
      return substring
    }
  }
  return null
}

export const strikeoutKeywords =
  () =>
  (message = '', whitelistHostnames = []): string[] => {
    const state = store.getState()
    const smsbadwordFromDB =
      state && state.messenger && state.messenger.smsbadword && state.messenger.smsbadword.option_value
    const smsbadwordData = smsbadwordFromDB ? JSON.parse(smsbadwordFromDB) : []
    // // Only block words for non-paying customers
    // if (isSubscribingCustomer) {
    //   return []
    // }
    // Block common phishing words for non-subscribing customers when external links are detected in message
    const foundLinks = linkify.find(message).filter((link) => {
      return link.type === 'url' && !containsAny(new URL(link.href).hostname, whitelistHostnames)
    })
    if (foundLinks.length > 0) {
      smsbadwordData.push(message)
    }

    const phishingWords =
      foundLinks.length === 0 ? [] : ['payment', 'restore', 'inactive', 'venmo', 'bank', 'cashapp', 'online']
    return [...smsbadwordData, ...phishingWords]
  }
